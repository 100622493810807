import logo from './logo.svg';
import './App.css';
import { ReactComponent as Svg } from './assets/Svg.svg';
import { ReactComponent as Type } from './assets/Hoober_logo.svg';


function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className='logo-container'>
          <Svg fill='white' className="App-logo" />
          {/* <Type fill='white' className='Type-logo' /> */}
        </div>
        <p>
          Copyright © Hoober, 2023
        </p>
        <a
          className="App-link"
          href="https://tell.ie/hoober"
          target="_blank"
          rel="noopener noreferrer"
        >
          Discover More
        </a>
      </header>
    </div>
  );
}

export default App;
